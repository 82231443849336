import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

const Resource = ({ image, title, content, className }) => (
  <div className={classnames("flex flex-col h-full", className)}>
    <div className="mb-4">{image}</div>
    <div className="text-2xl font-bold mb-4">{title}</div>
    <hr className="mb-4" />
    <div className="mb-4 flex-1">{content}</div>
  </div>
);

Resource.propTypes = {
  image: PropTypes.element.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
};

export default Resource;
