import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Resource from "../components/Resource";
import ResourceCallout from "../components/ResourceCallout";

const metadata = {
  title: "Soto Investigations | Criminal Defense Investigations & Surveillance",
  description:
    "Get the best criminal defense investigating and surveillance powered by years of law enforcement experience.",
  robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  canonical: "/",
  "og:locale": "en_US",
  "og:type": "website",
  "og:title": "Soto Investigations | Criminal Defense Investigations & Surveillance",
  "og:description": "Expert criminal defense services.",
  "og:url": "/",
  "og:site_name": "Soto Investigations",
  "og:image": "/src/assets/logo.png",
  "article:modified_time": "2022-03-06T13:57:48+00:00",
  "twitter:card": "summary_large_image",
  "twitter:label1": "Est. reading time",
  "twitter:data1": "1 minute",
};

const IndexPage = () => (
  <Layout metadata={metadata}>
    <div className="bg-blue-navy">
      <div className="flex flex-col items-center py-2 px-4 bg-gold-bright text-blue-gray text-center">
        <div className="flex items-center mb-2">
          <StaticImage
            src="../assets/veteran-owned.png"
            alt="Veteran Owned Business"
            style={{ width: "72px", height: "auto" }}
            placeholder="blurred"
            className="mr-8"
          />
          <StaticImage
            src="../assets/minority-owned.png"
            alt="Business Certifications - Minority Owned Business@seekpng.com"
            style={{ width: "90px", height: "auto" }}
            placeholder="blurred"
          />
        </div>
        <div className="text-lg">
          Ready to provide private comprehensive investigative and security services to individuals
          and businesses.
        </div>
      </div>
      <div className="text-center relative flex justify-center items-center">
        <StaticImage
          src="../assets/magnifier.jpg"
          alt="Magnifying Glass"
          style={{ width: "auto", height: "600px" }}
        />
        <div className="absolute text-primary px-8 sm:px-12">
          <h1 className="mb-4 sm:mb-8">
            <div>CSI: Providing Professional</div>
            <div>Investigative and Security Services</div>
          </h1>
          <span className="max-w-4xl text-lg sm:text-xl md:text-4xl mb-4;">
            With over 20 years of military and law enforcement experience, we know what it takes to
            get the job done.
          </span>
        </div>
      </div>
      <div className="text-white flex justify-center py-4 px-4 md:px-0">
        <div className="max-w-5xl">
          <ResourceCallout
            items={[
              <Resource
                image={
                  <StaticImage
                    src="../assets/law.jpg"
                    alt="Courtroom"
                    style={{ width: "100%", height: "180px" }}
                    placeholder="blurred"
                  />
                }
                title="Attorney Pre-Trial Investigations: Civil and Criminal Defense Consulting"
                content={
                  <div>
                    <p className="mb-2">
                      Our company provides the following pre-trial investigation consulting
                      services:
                    </p>
                    <ul className="list-disc ml-6">
                      <li>
                        Review of case files to determine areas of weak or insufficient evidence
                      </li>
                      <li>Failure to follow proper police procedures</li>
                      <li>
                        Obtain witness statements or investigate and interview potential witnesses
                      </li>
                      <li>Evidence gathering and background information</li>
                      <li>Witness testimony preparation</li>
                    </ul>
                  </div>
                }
                className="p-4 bg-blue-gray"
              />,
              <Resource
                image={
                  <StaticImage
                    src="../assets/digital-trace.jpg"
                    alt="Digital Trace"
                    style={{ width: "100%", height: "180px" }}
                    placeholder="blurred"
                  />
                }
                title="Skip Tracing and Background Checks"
                content={
                  <div>
                    <p className="mb-2">
                      Finding information about an individual by searching public and private
                      databases, court records, driver’s license, and vehicle registration records
                    </p>
                    <ul className="list-disc ml-6">
                      <li>Locating hard-to-find individuals</li>
                      <li>
                        Conducting employment background investigations for school districts or
                        corporate entities
                      </li>
                      <li>Discovering whereabout of individuals for trust or legal issues</li>
                    </ul>
                  </div>
                }
                className="p-4 bg-gold-bright text-blue-gray md:bg-blue-gray md:text-white"
              />,
            ]}
            className="mb-6"
            columns={2}
          />
          <ResourceCallout
            items={[
              <Resource
                image={
                  <StaticImage
                    src="../assets/pi.jpg"
                    alt="Surveillance"
                    style={{ width: "100%", height: "180px" }}
                    placeholder="blurred"
                  />
                }
                title="Surveillance and Cheating Spouse Investigations"
                content={
                  <div>
                    <p className="mb-2">
                      In depth techniques to identify, follow and document person(s).
                    </p>
                    <ul className="list-disc ml-6">
                      <li>Physical, photography, and video</li>
                      <li>Different packages and price ranges to fit your budget</li>
                      <li>One or two person surveillance teams</li>
                      <li>Multiple day/night surveillance to meet your needs</li>
                    </ul>
                  </div>
                }
                className="p-4 bg-blue-gray md:bg-gold-bright md:text-blue-gray"
              />,
              <Resource
                image={
                  <StaticImage
                    src="../assets/protection.jpg"
                    alt="Personal Protection"
                    style={{ width: "100%", height: "180px" }}
                    placeholder="blurred"
                  />
                }
                title="Personal Protection and Guard Services"
                content={
                  <div>
                    <p className="mb-2">
                      Our team possesses experience, communication and tactics that can help ensure
                      your safety.
                    </p>
                    <ul className="list-disc ml-6">
                      <li>Perform surveillance and counter-surveillance measures</li>
                      <li>Analyze potential threats and intelligence information</li>
                      <li>Armed and Unarmed protection</li>
                      <li>Provide VIP, Executive or Individual guard services</li>
                    </ul>
                  </div>
                }
                className="p-4 bg-gold-bright text-blue-gray md:bg-blue-gray md:text-white"
              />,
              <Resource
                image={
                  <StaticImage
                    src="../assets/hand-shake.jpg"
                    alt="Security Consulting"
                    style={{ width: "100%", height: "180px" }}
                    placeholder="blurred"
                  />
                }
                title="Security Consulting"
                content={
                  <div>
                    <p className="mb-2">
                      Our company believes our clients should be prepared for emergency security
                      situations. We can assist in formulating a security response plan catered to
                      your specific organization.
                    </p>
                    <ul className="list-disc ml-6">
                      <li>School safety</li>
                      <li>Corporate buildings</li>
                      <li>Emergency response education</li>
                    </ul>
                  </div>
                }
                className="p-4 bg-blue-gray md:bg-gold-bright md:text-blue-gray"
              />,
            ]}
            columns={3}
            className="mb-6"
          />
          <ResourceCallout
            items={[
              <Resource
                image={
                  <StaticImage
                    src="../assets/investigations-fraud.jpg"
                    alt="Investigations Fraud"
                    style={{ width: "100%", height: "180px" }}
                    placeholder="blurred"
                  />
                }
                title="Insurance Fraud and Workers’ Compensation Investigation"
                content={
                  <div>
                    <p className="mb-2">We investigate fraudulent insurance claims.</p>
                    <ul className="list-disc ml-6">
                      <li>Health insurance</li>
                      <li>Homeowners</li>
                      <li>Auto</li>
                      <li>Worker’s Compensation</li>
                    </ul>
                  </div>
                }
                className="p-4 bg-gold-bright text-blue-gray md:bg-blue-gray md:text-white"
              />,
              <Resource
                image={
                  <StaticImage
                    src="../assets/cold-cases.jpg"
                    alt="Cold Cases"
                    style={{ width: "100%", height: "180px" }}
                    placeholder="blurred"
                  />
                }
                title="Missing Persons and Cold Case Investigations"
                content={
                  <div>
                    <p className="mb-2">
                      Finding answers to unsolved cases with an investigative team of Cold Case,
                      Homicide, Assaultive and Sexual Assault cases.
                    </p>
                    <ul className="list-disc ml-6">
                      <li>Years of investigative experience regarding cold case investigations</li>
                      <li>Finding and re-interviewing witnesses</li>
                      <li>In depth review of police reports and evidence</li>
                      <li>Using database information to find missing persons</li>
                    </ul>
                  </div>
                }
                className="p-4 bg-blue-gray"
              />,
            ]}
            columns={2}
            className="mb-6"
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
