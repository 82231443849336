import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

const MAP = {
  COLS: {
    2: "md:grid-cols-2",
    3: "md:grid-cols-3",
    4: "md:grid-cols-4",
    5: "md:grid-cols-5",
  },
};

const ResourceCallout = ({ items, columns, className }) => (
  <div
    className={classnames(
      "md:grid",
      "md:grid-flow-col",
      "md:gap-6",
      MAP.COLS[columns] || "md:grid-cols-none",
      className
    )}
  >
    {items.map((item, index) => (
      <div key={index} className="mb-6 md:mb-0 last:mb-0">
        {item}
      </div>
    ))}
  </div>
);

ResourceCallout.propTypes = {
  items: PropTypes.arrayOf(PropTypes.element).isRequired,
  columns: PropTypes.number,
  className: PropTypes.string,
};

export default ResourceCallout;
